.display {
    display: flex;
    justify-content: stretch;
    position: relative;
    max-height: 100vh;
    max-width: 100vw;
    aspect-ratio: 2/1;
}

.display__column {
    min-width: 1px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.display__pixel {
    flex-grow: 1;
    min-height: 1px;
    min-width: 1px;
}

.display__pixel.active {
    background: orange;
}

.display__pixel.inactive {
    background: black;
}
